/* Feel free to change duration  */

.animated {
    -webkit-animation-duration: 1000 ms;
    animation-duration: 1000 ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/* .owl-animated-out - only for current item */


/* This is very important class. Use z-index if you want move Out item above In item */

.owl-animated-out {
    z-index: 1
}


/* .owl-animated-in - only for upcoming item
/* This is very important class. Use z-index if you want move In item above Out item */

.owl-animated-in {
    z-index: 0
}


/* .fadeOut is style taken from Animation.css and this is how it looks in owl.carousel.css:  */

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
