.main-nav {
    float: right;
    transition: all 0.4s linear;
    position: absolute;
    left: 0;
    right: 18px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    & > ul li {
        position: relative;
        float: left;
        list-style: none;
        -webkit-transition: all ease-in-out 0.3s;
        transition: all ease-in-out 0.3s;
        &:last-child {
            border-right: none;
        }
        &:hover {
            background-color: #1b2d41;
            .sub-menu {
                display: block;
            }
            a {
                color: $color-white;
                text-decoration: none;
            }
        }
        a {
            text-transform: uppercase;
            color: $main-red;
            font-family: $font-oswald;
            font-size: 18px;
            padding: 3px 15px;
        }
    }
    ul.sub-menu {
        display: none;
        position: absolute;
        background-color: #1b2d41;
        padding: 5px 0;
        width: 220px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 200;
        left: 0;
        z-index: 10;
        text-align: left;
        li {
            display: block;
            padding: 5px 0;
            width: 100%;
        a {
            font-size: 12px;
            font-family: $font-montserrat;
            text-transform: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    }
}

@media screen and (max-width: 1200px) {
    .menu {
        padding: 0;
        margin: 0;
        text-align: center;
    }
    .main-nav {
        display: none;
        width: 100%;
        position: relative;
        height: 310px;
        max-height: 100vh;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        ul li {
            width: 100%;
            display: block;
            position: relative;
            border-bottom: 1px solid #eaeaea;
            padding: 10px 0;
            transition: all ease-in-out 0.3s;
            text-align: center;
            a {
                font-size: 16px;
            }
            &:last-child {
                border: 0;
            }
            &:hover {
                background-color: #fff;
                & a {
                    color: $main-red;
                }
                & .sub-menu {
                    display: none;
                }
            }
            &.menu-item-has-children {
                position: relative;
                &:after {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 18px;
                    background: url(../img/arrow.png) no-repeat;
                    width: 20px;
                    height: 12px;
                    background-size: 12px 7px;
                    cursor: pointer;
                }
            }
        }
        ul.sub-menu {
            width: 100%;
            color: #ffffff;
            font-weight: 300;
            z-index: 10;
            position: relative;
            background-color: #fff;
            & li {
            border: none;
            padding: 5px 0;
            }
        }
    }
}
