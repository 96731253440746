a {
    text-decoration: none;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
    color: $main-red;
    &:hover {
        text-decoration: underline;
    }
}

h1 {
    font-family: $font-oswald;
    font-size: 48px;
    color: $main-red;
    line-height: 52px;
}

h2 {
    font-family: $font-oswald;
    font-size: 44px;
    color: $main-red;
    margin: 20px 0;
}

h3 {
    font-family: $font-oswald;
    font-size: 34px;
    color: $main-red;
    margin: 10px 0;
}

p {
    font-family: $font-montserrat;
    font-weight: 300;
    font-size: 16px;
}

ul li {
    font-family: $font-montserrat;
    font-weight: 300;
    font-size: 16px;
}

input,
textarea {
    width: 300px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    outline: none;
}

[type="submit"] {
    color: $color-white;
    background-color: $main-red;
    width: 160px;
    text-transform: uppercase;
    cursor: pointer;
}

[type="checkbox"],
[type="radio"] {
    width: 30px;
    margin: 20px 0px 10px 0px;
}

span.wpcf7-list-item {
    width: 100%;
}

.wrapper {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}

@media screen and (max-width: 1170px) {
    .wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 44px;
    }
    h2 {
        font-size: 40px;
    }
    h3 {
        font-size: 26px;
    }
}
