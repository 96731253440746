.text--bold {
    font-weight: 600;
}

.text--white {
    color: $color-white;
}

.text--blue {
    color: $main-blue;
}

.text--italic {
    font-style: italic;
}

.text--big {
    font-size: 34px;
}

.text--small {
    font-family: $font-montserrat;
    font-size: 14px;
    color: $color-white;
    font-weight: 100;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--center {
    text-align: center;
}

.pt50 {
    padding-top: 50px;
}

.pb50 {
    padding-bottom: 50px;
}

.pt20 {
    padding-top: 20px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

@media screen and (max-width: 1170px) {
    .pull-left {
        float: none;
    }
    .pull-right {
        float: none;
    }
}

@media screen and (max-width: 768px) {
    .xs-responsive {
        width: 100%;
    }
    .text--big {
    font-size: 30px;
}
}
