.header {
    &--top {
        padding: 10px 0;
        -webkit-transition: all ease-in-out 0.3s;
        transition: all ease-in-out 0.3s;
        border-bottom: 10px solid #e32626;
        position: fixed;
        z-index: 9999;
        width: 100%;
        background: #fff;
        top: 0;
        .hamburger-icon {
            background: url(../img/menu-icon.png) no-repeat;
            display: block;
            position: absolute;
            right: 15px;
            top: 55px;
            width: 50px;
            height: 50px;
            background-size: 50px 50px;
            display: none;
            cursor: pointer;
        }
        .logo {
            position: relative;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            z-index: 5;
        }
    }
}

.phone {
    font-size: 20px;
    text-transform: uppercase;
    font-family: $font-oswald;
    color: $main-blue;
    top: 18px;
    float: right;
    position: relative;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    a {
        color: $main-blue;
        &:hover {
            text-decoration: none;
        }
    }
}

.scrolled {
    position: fixed;
    width: 100%;
    z-index: 20;
    background: $color-white;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    .main-nav {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .logo img {
        width: 50%;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
}

@media screen and (max-width: 1200px) {
    .header--top {
        .logo img {
            width: 80px !important;
            right: 20px;
            top: 5px;
            position: relative;
        }
        .phone {
            font-size: 16px;
            top: 25px;
            right: 50px;
        }
        .hamburger-icon {
            display: block;
            top: 10px;
        }
    }
    & .scrolled {
        .main-nav {
            top: 0;
            left: 0;
            display: none;
        }
        .logo img {
            width: 140px;
            -webkit-transition: all ease-in-out 0.3s;
            transition: all ease-in-out 0.3s;
        }
    }
}
