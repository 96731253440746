/* CTA Section Styles */

.cta-wrapper {
    width: 1300px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}

.section--cta {
    position: relative;
    margin-top: -240px;
}

.img-overlay {
    display: inline-block;
    position: relative;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
    z-index: 1;
}

.img-overlay:after {
    content: '';
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: #1b2d41;
    opacity: 0.67;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}

.img-overlay:hover:after {
    opacity: 0;
}

.img-overlay:hover .img-content {
    display: none;
}

.img-content {
    position: absolute;
    z-index: 11;
    width: 100%;
    padding: 30px;
    display: block;
    height: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 10px;
    opacity: 1;
    box-sizing: border-box;
}

@media screen and (max-width: 1300px) {
    .cta-wrapper {
        width: 100%;
    }
}
@media screen and (max-width: 1170px) {
    .img-overlay {
        width: 100%;
    }
    .section--cta {
        margin-top: 0;
        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }
    }
}
