.inner--header {
    img {
        max-height: 310px;
        border-bottom: 49px solid #1b2d41;
    }
}


/* Services CTA section styles */

.section--main__cta {
    .services-cta {
        border: 2px solid $main-red;
    }
    .img-content {
        padding: 0;
    }
    .img-overlay {
        img {
            width: 100%
        }
        &:after {
            background-color: #fff;
        }
        h3 {
            font-size: 22px;
            color: $main-blue;
            position: relative;
            z-index: 20;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
        &:hover {
            .img-content {
                display: block;
                h3 {
                    color: $color-white;
                }
            }
            &:after {
                background-color: $main-red;
                opacity: 1;
            }
        }
    }
}
.job-title {
    cursor: pointer;
    border: 1px solid rgba(6, 13, 123, 0.27);
    padding: 5px 30px;
    margin: 20px 0;
    border-radius: 3px;
    img {
        width: 20px;
        float: right;
        position: relative;
        top: 5px;
        transition: all .4s ease-in-out;
        &.active {
            transform: rotateX( 180deg );
        }
    }
}
.job-descr {
    display: none;
    padding: 10px 40px;
}

@media screen and (max-width: 1200px) {
    .section--main__cta .services-cta {
        margin: 13px auto;
        width: 370px;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .inner--header .section__title {
        top: 0;
        &.large--text {
            font-size: 60px;
        }
    }
    .section--main__cta .img-overlay h3 {
            font-size: 20px;
        }
     .section--main__cta .services-cta {
        width: 100%;
    }
}
