section {
    position: relative;
}

.section {
    &--top {
        position: relative;
        margin-top: 142px;
        img {
            width: 100%;
            object-fit: cover;
            max-height: 680px;
            border-bottom: 81px solid #1b2d41;
            position: relative;
        }
    }
    &__title {
        font-size: 36px;
        color: $color-white;
        font-family: $font-raleway;
        position: absolute;
        top: 0;
        bottom: 50px;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        vertical-align: middle;
        &.small--title {
            bottom: 200px;
        }
        &.large--title {
            font-size: 77px;
            font-family: $font-oswald;
        }
    }
    &--parallax {
        background: url(../img/parallax-bg.jpg) no-repeat;
        height: 570px;
        display: block;
        background-attachment: fixed;
        background-size: cover;
        .section-title {
            position: absolute;
            top: 60px;
            display: block;
            width: 60%;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-family: $font-oswald;
        }
    }
}

map {
    img {
        width: 100%;
        height: auto;
    }
    width: 100%;
    .elk-city {
        width: 13%;
        height: 5%;
        position: absolute;
        background: rgba(128, 128, 128, 0.73);
        right: 45%;
        top: 20%;
    }
}
@media screen and (max-width: 1200px) {
    .section {
        &--top {
            margin-top: 80px;
        }
        &--parallax {
            background-attachment: initial !important;
            height: 400px;
            padding: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .section {
        &__title {
            top: 100px;
            &.small--title {
                /*top: 30px;*/
                font-size: 30px;
            }
            &.large--title {
                font-size: 38px;
            }
        }
        &--top img {
            border-bottom: none;
            height: 300px;
        }
    }
}
