/* Footer & Copyright section styles */

.footer {
    &--top {
        background-color: #e32626;
        padding: 30px 0;
    }
    &--bottom {
        background-color: #1b2d41;
        padding: 15px 0;
        ul {
            &.footer__menu {
                float: left;
                padding: 13px;
                margin: 0;
            }
            li {
                float: left;
                list-style: none;
                padding: 0 15px;
                border-right: 1px solid #ffffff;
                color: $color-white;
                font-size: 13px;
                font-family: $font-montserrat;
                font-weight: 200;
                &:last-child {
                    border-right: none;
                }
                &:hover a {
                    color: rgba(255, 255, 255, 0.5);
                }
                a {
                    color: $color-white;
                        &:hover {
                            text-decoration: none;
                        }
                }
            }
        }
        p {
            font-size: 13px;
            color: $color-white;
            font-family: $font-montserrat;
            font-weight: 200;
            a {
                color: $color-white;
                &:hover {
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
        .copyright {
            border-right: 1px solid #ffffff;
            padding: 0px 15px;
            position: relative;
            top: 3px;
        }
        .menu {
            float: left;
            padding: 0;
        }
    }
    &--nav {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        li {
            float: left;
            list-style: none;
            padding: 3px 15px;
            &:hover a {
                color: rgba(255, 255, 255, 0.7);
                text-decoration: none;
            }
            a {
                font-family: $font-oswald;
                font-size: 18px;
                color: $color-white;
            }
        }
    }
}


/* Social Icons Style */

.socials {
    top: 10px;
    position: relative;
    .social-icon {
        position: relative;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        -ms-transition: all ease 0.3s;
        transition: all ease 0.3s;
        opacity: 1;
        width: 50px;
        height: 50px;
        float: left;
        margin: auto 5px;
        &.facebook {
            background: url(../img/facebook.png) no-repeat;
        }
        &.linkedin {
            background: url(../img/linkedin.png) no-repeat;
        }
        &:hover {
            opacity: 0.7;
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        &--nav {
            width: 100%;
            ul {
                padding: 0;
                li {
                    width: 100%;
                    padding: 15px 0;
                }
            }
        }
        &--bottom ul {
            width: 100%;
            padding: 15px 0;
            li {
                width: 100%;
                padding: 10px 0;
                margin: 0;
                text-align: center;
                border-right: none;
            }
        }
    }
    .copyright {
        display: block;
        position: relative;
        float: none !important;
        text-align: center;
        border: none !important;
    }
    .socials {
        width: 100%;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}
